import { Injectable, signal, computed } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class ChatService {
    #showChat = signal<boolean>(false);

    public showChat = computed(() => this.#showChat());

    constructor() {}

    private setLoading(show: boolean) {
        this.#showChat.set(show);
    }
    
    show() {
        this.setLoading(true);
    }

    hide() {
        this.setLoading(false);
    }
}
